import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { isEmpty, REGEX } from 'src/helpers/validation-utils';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import GenericInput from 'src/components/input-components/GenericTextInput';
import GenericButton from 'src/components/GenericButton';
import { UpdateDefaultOrderTime } from 'src/redux/user/userReducer';

const useStyles = makeStyles()((theme) => {
  return {
    mainContainer: {
      backgroundColor: '#fff',
      padding: '20px',
      width: '100%',
      maxWidth: '600px',
      borderRadius: '20px',
      border: '1px solid #D1D3D2',
      margin: '0',
    },
    container: {
      width: '100%',
      padding: '0 25px',
    },
    headerContainer: {
      width: '100%',
      height: '80px',
      borderBottom: '1px solid #D1D3D2',
      padding: '0 25px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerTitles: {
      textAlign: 'center',
      flex: 1,
    },
    headerTitle: {
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.colors.alpha.black[70],
      fontFamily: 'Poppins-Medium',
    },
    headerSubtitle: {
      fontSize: '12px',
      lineHeight: '18px',
      color: theme.colors.alpha.black[70],
      fontFamily: 'Poppins-Regular',
    },
    infoContainer: {
      margin: '25px 0',
      padding: '20px',
      borderRadius: '20px',
      backgroundColor: '#fff',
      border: '1px solid #D1D3D2',
    },
    formSubmitButton: {
      display: 'block',
      width: '70%',
      margin: '20px auto 0',
    },
    infoText: {
      fontFamily: 'Poppins-Medium',
      fontSize: '14px',
      color: theme.colors.alpha.black[70],
      marginBottom: '16px',
      textAlign: 'center',
    },
  };
});

const UpdateRestaurantInfoScreen: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [values, setValues] = useState<Record<string, any>>({
    password: '',
    newOrderTime: user.defaultOrderTime,
  });
  const [errors, setErrors] = useState<Record<string, string>>({
    password: '',
    newOrderTime: '',
  });
  const [touched, setTouched] = useState<Record<string, boolean>>({
    password: false,
    newOrderTime: false,
  });
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { classes } = useStyles();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    // Any initialization logic
  };

  const handleChange = (key: string, value: string) => {
    setValues({ ...values, [key]: value });
    setTouched({ ...touched, [key]: true });
    setErrors({
      ...errors,
      [key]: validate(key, value),
    });
  };

  const handleBlur = (key: string) => {
    setTouched({ ...touched, [key]: true });
  };

  const validate = (key: string, value: string): string => {
    let error = '';
    if (key === 'password') {
      if (isEmpty(value)) {
        error = 'Required';
      }
    } else if (key === 'newOrderTime') {
      if (isEmpty(value)) {
        error = 'Required';
      } else if (!REGEX.OnlyNumbers.test(value) || isNaN(parseInt(value))) {
        error = 'Invalid number';
      } else if (parseInt(value) < 5) {
        error = 'Must be at least 5 minute';
      }
    }
    return error;
  };

  const validateForm = () => {
    let tempTouched = { ...touched };
    let tempErrors = { ...errors };
    Object.entries(values).forEach(([key, value]) => {
      tempTouched[key] = true;
      tempErrors = { ...tempErrors, [key]: validate(key, value) };
    });
    setTouched(tempTouched);
    setErrors(tempErrors);
    return Object.values(tempErrors).every((error) => isEmpty(error));
  };

  const submit = async () => {
    if (!submitting) {
      setSubmitting(true);
      if (validateForm()) {
        let newOrderTime = parseInt(values.newOrderTime);
        if (newOrderTime === user.defaultOrderTime) {
          toast.info('No change has been detected');
        } else {
          try {
            const response = await DataService.post(
              'api/Restaurant/update-order-time',
              {
                password: values.password.trim(),
                newOrderTime: newOrderTime,
              }
            );

            if (response.ok) {
              const result: { success: boolean } = await response.json();
              if (result.success) {
                toast.success(
                  'Order preparation time has been changed successfully.'
                );
                dispatch(UpdateDefaultOrderTime(newOrderTime));
              } else {
                toast.error('An Error Occurred');
              }
            } else {
              toast.error('An Error Occurred');
            }
          } catch (err) {
            toast.error('An Error Occurred');
          } finally {
            setSubmitting(false);
            setValues({ ...values, password: '' });
          }
        }
      } else {
        setSubmitting(false);
      }
    }
  };

  return (
    <div className={classes.mainContainer}>
      {/* <div className={classes.headerContainer}>
        <div className={classes.headerTitles}>
          <h2 className={classes.headerTitle}>{user.name}</h2>
          <p className={classes.headerSubtitle}>Restaurant information</p>
        </div>
      </div> */}
      <div className={classes.container}>
        {/* <div className={classes.infoContainer}> */}
          <p className={classes.infoText}>
            Order Time Settings
          </p>
          <GenericInput
            title="Current password"
            name="password"
            placeholder="********"
            type="password"
            value={values.password}
            onChange={(value) => handleChange('password', value)}
            onBlur={() => handleBlur('password')}
            error={touched.password ? errors.password : ''}
            disabled={submitting}
          />
          <GenericInput
            name="time"
            title="Default Order Time"
            type="number"
            value={values.newOrderTime?.toString() || ''}
            onChange={(value) => handleChange('newOrderTime', value)}
            onBlur={() => handleBlur('newOrderTime')}
            error={touched.newOrderTime ? errors.newOrderTime : ''}
            disabled={submitting}
          />
          <GenericButton
            text="Update"
            className={classes.formSubmitButton}
            onClick={() => submit()}
            disabled={submitting}
          />
        </div>
      {/* </div> */}
    </div>
  );
};

export default UpdateRestaurantInfoScreen;
