import React, { useState, useEffect, useMemo, useRef } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Divider,
} from "@mui/material";
import { DataService } from "src/services/data-service";
import { toast } from "react-toastify";
import { makeStyles } from 'tss-react/mui';
import { isEmpty } from "src/helpers/validation-utils";
import { cloneObjectBasic } from "src/helpers/utils";
import GenericInput from "src/components/input-components/GenericTextInput";
import GenericSelectInput from "src/components/input-components/GenericSelectInput";
import GenericPhoneInput from "src/components/input-components/GenericPhoneInput";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import GenericButton from "src/components/GenericButton";

const useStyles = makeStyles()((theme) => ({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    header: {
        backgroundColor: '#FDD229',
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    headerTitle: {
        color: '#000',
        fontSize: 20,
        lineHeight: '30px',
        fontFamily: 'Poppins-Regular',
        fontWeight: 'bold',
    },
    actions: {
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: '1px solid #D1D3D4',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    saveButton: {
        width: '100px',
    },
    closeButton: {
        border: '1px solid #000',
        width: '100px',
        '&:hover': {
            border: '1px solid #000',
        },
    },
}));

interface ICityDetails {
    code: string;
    name: string;
    active: boolean;
    neighborhoods: {
        code: string;
        name: string;
        active: boolean;
    }[];
}

interface ILovDataResponse {
    success: boolean;
    data: ICityDetails[];
}

interface IPageStateForm {
    values: {
        name: string;
        address: string;
        city: string;
        neighberhood: string;
        mobileNumber: string;
    };
    touched: Record<string, boolean>;
    errors: Record<string, string>;
}

const getInitialPageState = (mobileNumber?: string): IPageStateForm => ({
    values: {
        name: "",
        address: "",
        city: "",
        neighberhood: "",
        mobileNumber: mobileNumber || "",
    },
    errors: {},
    touched: {},
});

interface ICreateCustomerModalProps {
    onClose: () => void;
    onSuccess: (mobileNumber: string) => void;
    mobileNumber?: string;
}

const CreateCustomerModal: React.FC<ICreateCustomerModalProps> = ({
    onClose,
    onSuccess,
    mobileNumber,
}) => {
    const { classes } = useStyles();
    const [pageState, setPageState] = useState<IPageStateForm>(
        getInitialPageState(mobileNumber)
    );
    const [citiesData, setCitiesData] = useState<ICityDetails[]>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const addressRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const initialize = async () => {
            const response = await DataService.get("api/Lov/all", {});
            if (response.ok) {
                const result: ILovDataResponse = await response.json();
                setCitiesData(result.data);
            } else {
                onClose();
                toast.error("An Error Occurred");
            }
        };
        initialize();
    }, [onClose]);

    const citiesList = useMemo(
        () =>
            citiesData
                ? Object.fromEntries(citiesData.map((c) => [c.code, c.name]))
                : {},
        [citiesData]
    );

    const nbList = useMemo(() => {
        const currentCity = citiesData?.find((c) => c.code === pageState.values.city);
        return currentCity
            ? Object.fromEntries(
                currentCity.neighborhoods.map((c) => [c.code, c.name])
            )
            : {};
    }, [citiesData, pageState.values.city]);

    const onFieldUpdate = (
        name: keyof IPageStateForm["values"],
        value: string,
        touched = false
    ) => {
        const newPageState = cloneObjectBasic(pageState);
        newPageState.values[name] = value;
        newPageState.errors[name] = validateField(name, value);
        if (touched) {
            newPageState.touched[name] = true;
        }
        if (name === "city") {
            newPageState.values.neighberhood = "";
            newPageState.errors.neighberhood = "";
            newPageState.touched.neighberhood = false;
        }
        setPageState(newPageState);
    };

    const validateField = (name: string, value: string) => {
        if (isEmpty(value)) {
            return "Required";
        }

        if (name === "mobileNumber") {
            const valueToValidate = !value?.startsWith("+") ? "+" + value : value;
            if (!isPossiblePhoneNumber(valueToValidate)) {
                return "Invalid Mobile Number";
            }
        }
        return "";
    };

    const onBlur = (name: keyof IPageStateForm["values"]) => {
        const newPageState = cloneObjectBasic(pageState);
        newPageState.touched[name] = true;
        newPageState.errors[name] = validateField(name, pageState.values[name]);
        setPageState(newPageState);
    };

    const validateForm = () => {
        const newPageState = cloneObjectBasic(pageState);
        newPageState.touched = {
            name: true,
            address: true,
            city: true,
            neighberhood: true,
            mobileNumber: true,
        };
        newPageState.errors = {
            name: validateField("name", pageState.values.name),
            address: validateField("address", pageState.values.address),
            city: validateField("city", pageState.values.city),
            neighberhood: validateField("neighberhood", pageState.values.neighberhood),
            mobileNumber: validateField("mobileNumber", pageState.values.mobileNumber),
        };
        setPageState(newPageState);
        return Object.values(newPageState.errors).every((a) => isEmpty(a));
    };

    const submit = async () => {
        if (!submitting) {
            const isValid = validateForm();
            if (isValid) {
                const mobileNumber = pageState.values.mobileNumber?.startsWith("+") ? pageState.values.mobileNumber : "+" + pageState.values.mobileNumber;
                setSubmitting(true);
                const data = {
                    name: pageState.values.name,
                    mobileNumber: mobileNumber.replace(/\s/g, ""),
                    contactAddresses: [
                        {
                            description: pageState.values.address,
                            neighborhoodCode: pageState.values.neighberhood,
                            cityCode: pageState.values.city,
                            contactId: 0,
                        },
                    ],
                };
                const response = await DataService.post("api/contact/create", data);
                if (response.ok) {
                    onSuccess(mobileNumber);
                    onClose();
                } else {
                    toast.error("An Error Occurred");
                }
                setSubmitting(false);
            } else {
                toast.error("Incomplete Form");
            }
        }
    };

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle className={classes.header}>
                <div className={classes.headerTitle}>Add New Customer</div>
            </DialogTitle>
            <DialogContent dividers className={classes.container}>
                {citiesData ? (
                    <>
                        <GenericInput
                            type="text"
                            name="name"
                            title="Customer Full Name"
                            value={pageState.values.name}
                            onChange={(v) => onFieldUpdate("name", v)}
                            error={pageState.errors.name}
                            onBlur={onBlur}
                            disabled={submitting}
                        />
                        <GenericInput
                            type="text"
                            name="address"
                            title="Customer Full Address"
                            value={pageState.values.address}
                            onChange={(v) => onFieldUpdate("address", v)}
                            error={pageState.errors.address}
                            onBlur={onBlur}
                            disabled={submitting}
                        />
                        <GenericSelectInput
                            type="text"
                            title="City"
                            name="city"
                            value={pageState.values.city}
                            onChange={(v) => onFieldUpdate("city", v)}
                            error={pageState.errors.city}
                            items={citiesList}
                            disabled={submitting}
                            watermark="Select City"
                        />
                        <GenericSelectInput
                            type="text"
                            title="Neighborhood"
                            name="neighberhood"
                            value={pageState.values.neighberhood}
                            onChange={(v) => onFieldUpdate("neighberhood", v)}
                            error={pageState.errors.neighberhood}
                            items={nbList}
                            disabled={submitting}
                            watermark="Select Neighborhood"
                        />
                        <GenericPhoneInput
                            type="text"
                            name="mobileNumber"
                            title="Customer Mobile Number"
                            value={pageState.values.mobileNumber}
                            onChange={(v) => onFieldUpdate("mobileNumber", v)}
                            error={pageState.errors.mobileNumber}
                            onBlur={onBlur}
                            disabled={submitting}
                        />
                    </>
                ) : (
                    <CircularProgress />
                )}
            </DialogContent>
            <Divider />
            <DialogActions className={classes.actions}>
                <GenericButton
                    variant="outlined"
                    onClick={() => {
                        onClose();
                    }}
                    disabled={submitting}
                    className={classes.closeButton}
                    text={'Cancel'}
                    buttonColor={'#FFF'}
                />
                <GenericButton
                    variant="contained"
                    onClick={submit}
                    disabled={submitting}
                    className={classes.saveButton}
                    text={submitting ? "Saving..." : "Save"}
                />
            </DialogActions>
        </Dialog>
    );
};

export default CreateCustomerModal;