import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  Skeleton,
} from '@mui/material';
import { toast } from 'react-toastify';
import { formatCurrencyNumber } from 'src/helpers/formatting-utils';
import { DataService } from 'src/services/data-service';
import { useAppSelector } from 'src/redux/hooks';
import { makeStyles } from 'tss-react/mui';
import GenericDatePicker from 'src/components/input-components/GenericDatePicker';
import GenericButton from 'src/components/GenericButton';

const useStyles = makeStyles()({
  container: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '8px',
    marginTop: '16px',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  headerTitle: {
    color: '#333',
    fontSize: '24px',
    fontWeight: 600,
    marginRight: 'auto',
  },
  restaurantId: {
    color: '#333',
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: '10px',
  },
  timeRangePickersContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '30px',
  },
  timePickerButton: {
    border: '1px solid #D1D3D4',
    backgroundColor: '#FFFFFF',
    color: '#414042',
    padding: '10px 20px',
    borderRadius: '20px',
    fontWeight: 500,
    width: '20%',
    '&.active': {
      color: '#FFFFFF',
    },
  },
  filterButtonDaily: {
    '&.active': {
      backgroundColor: '#333',
      color: "#fff"
    },
    '&:hover': {
      backgroundColor: 'rgb(51 51 51 / 90%)',
      color: "#fff"
    }
  },
  filterButtonWeekly: {
    '&.active': {
      backgroundColor: '#CC0700',
    },
  },
  filterButtonMonthly: {
    '&.active': {
      backgroundColor: '#3DB851',
    },
  },
  dateFiltersContainer: {
    border: '1px solid #D1D3D2',
    width: '70%',
    margin: '20px auto',
    padding: '20px 30px',
    borderRadius: '16px',
    backgroundColor: '#FFFFFF',
  },
  dateInputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dateInputContainer: {
    width: '48%',
  },
  filterButtonContainer: {
    margin: '20px auto',
    display: 'block',
    width: '40%',
  },
  metricsHelperText: {
    color: '#808181',
    fontSize: '12px',
    textAlign: 'center',
    marginBottom: '20px',
  },
  metricsTableContainer: {
    border: '1px solid #D1D3D4',
    borderRadius: '20px',
    padding: '20px',
  },
  metricHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 0 20px',
    borderBottom: '1px solid #D1D3D4',
    alignItems: "center",
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  metricsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    borderBottom: '1px solid #D1D3D4',
    alignItems: "center",
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  metricsRowTitleContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  metricsRowValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    textAlign: 'right',
  },
  metricsHeaderTitle: {
    color: '#333',
    fontSize: '16px',
    fontWeight: "bold",
  },
  metricsRowTitle: {
    color: '#333',
    fontSize: '16px',
    fontWeight: 500,
  },
  metricsRowSubtitle: {
    color: '#808181',
    fontSize: '12px',
    marginTop: '4px',
  },
  metricsRowValue: {
    color: '#333',
    fontSize: '18px',
    fontWeight: 600,
  },
  metricsRowPercentage: {
    marginLeft: '10px',
    fontSize: '12px',
    '&.positive': {
      color: '#3DB951',
    },
    '&.negative': {
      color: '#CD2422',
    },
  },
  metricsRowSmallValue: {
    color: '#808181',
    fontSize: '12px',
    textAlign: 'right',
  },
  noBorder: {
    borderBottom: 'none',
  },
});

interface IAPIResponse {
  message: string;
  success: boolean;
  data: { current: IStatisticsDataObject; previous: IStatisticsDataObject };
}

interface IStatisticsDataObject {
  averageOrderPrice: number;
  numberOfOrders: number;
  totalOrderPrice: number;
}

const BusinessScreen = () => {
  const { classes, cx } = useStyles();
  const user = useAppSelector((state) => state.user);
  const [selectedTimePicker, setSelectedTimePicker] = useState<string>('Daily');
  const [startDate, setStartDate] = useState<Date>(getInitialStartDate());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [data, setData] = useState<{
    current: IStatisticsDataObject;
    previous: IStatisticsDataObject;
    previousPercentages: IStatisticsDataObject;
  }>({
    current: { averageOrderPrice: 0, numberOfOrders: 0, totalOrderPrice: 0 },
    previous: { averageOrderPrice: 0, numberOfOrders: 0, totalOrderPrice: 0 },
    previousPercentages: {
      averageOrderPrice: 0,
      numberOfOrders: 0,
      totalOrderPrice: 0,
    },
  });

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getData(startDate, endDate);
  }, []);

  const getPercentage = (newValue: number, oldValue: number) => {
    if (oldValue === 0) {
      return 0;
    } else {
      const difference = newValue - oldValue;
      const percentageChange = (difference / oldValue) * 100;
      return percentageChange;
    }
  };

  const getData = async (start: Date | null, end: Date | null) => {
    setLoading(true);
    try {
      const response = await DataService.getWithParams(
        '/api/Restaurant/history-summary',
        {
          startDate: start?.toLocaleDateString('en-CA'),
          endDate: end?.toLocaleDateString('en-CA'),
        }
      );

      if (response.ok) {
        const result: IAPIResponse = await response.json();
        if (result.success) {
          setData({
            current: result.data.current,
            previous: result.data.previous,
            previousPercentages: {
              averageOrderPrice: getPercentage(
                result.data.current.averageOrderPrice,
                result.data.previous.averageOrderPrice
              ),
              numberOfOrders: getPercentage(
                result.data.current.numberOfOrders,
                result.data.previous.numberOfOrders
              ),
              totalOrderPrice: getPercentage(
                result.data.current.totalOrderPrice,
                result.data.previous.totalOrderPrice
              ),
            },
          });
          setLoading(false);
        } else {
          toast.error('An Error Occurred');
        }
      } else {
        toast.error('An Error Occurred');
      }
    } catch (err) {
      toast.error('An Error Occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (mode: 'Daily' | 'Weekly' | 'Monthly') => {
    if (selectedTimePicker !== mode) {
      setSelectedTimePicker(mode);
      let newStartDate = new Date();
      let newEndDate = new Date();
      switch (mode) {
        case 'Daily':
          setStartDate(newStartDate);
          setEndDate(newEndDate);
          break;
        case 'Weekly':
          newStartDate.setDate(newStartDate.getDate() - 7);
          setStartDate(newStartDate);
          setEndDate(newEndDate);
          break;
        case 'Monthly':
          newStartDate.setMonth(newStartDate.getMonth() - 1);
          setStartDate(newStartDate);
          setEndDate(newEndDate);
          break;
      }
      getData(newStartDate, newEndDate);
    }
  };

  function getInitialStartDate() {
    let date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <Box className={classes.headerContainer}>
        <Typography className={classes.headerTitle}>Business</Typography>
      </Box>

      <Typography className={classes.restaurantId}>{user.user.name}</Typography>

      <Box className={classes.timeRangePickersContainer}>
        {['Daily', 'Weekly', 'Monthly'].map((option) => (
          <Button
            key={option}
            className={cx(
              classes.timePickerButton,
              classes[`filterButton${option}` as keyof typeof classes],
              {
                active: selectedTimePicker === option,
              }
            )}
            onClick={() => handleTabChange(option as 'Daily' | 'Weekly' | 'Monthly')}
          >
            {option}
          </Button>
        ))}
      </Box>

      <Box className={classes.dateFiltersContainer}>
        <Box className={classes.dateInputsContainer}>
          <Box className={classes.dateInputContainer}>
            <GenericDatePicker
              title={'Start date'}
              value={startDate}
              onChange={(value) => setStartDate(value)}
              name={'startDate'}
              maxDate={new Date()}
            />
          </Box>
          <Box className={classes.dateInputContainer}>
            <GenericDatePicker
              title={'End date'}
              value={endDate}
              onChange={(value) => setEndDate(value)}
              name={'endDate'}
              maxDate={new Date()}
            />
          </Box>
        </Box>
        <GenericButton
          text={'Filter'}
          className={classes.filterButtonContainer}
          onClick={() => getData(startDate, endDate)}
        />
      </Box>

      <Typography className={classes.metricsHelperText}>
        Metrics are compared to the previous time range
      </Typography>

      {loading ? (
        <Box className={classes.metricsTableContainer}>
          <Box className={classes.metricHeader}>
            <Skeleton variant="text" width="30%" height={30} />
          </Box>
          <Box className={classes.metricsRow}>
            <Box className={classes.metricsRowTitleContainer}>
              <Skeleton variant="text" width="50%" height={25} />
              <Skeleton variant="text" width="40%" height={20} />
            </Box>
            <Box className={classes.metricsRowValueContainer}>
              <Skeleton variant="text" width="40%" height={25} />
              <Skeleton variant="text" width="30%" height={20} />
              <Skeleton variant="text" width="50%" height={20} />
            </Box>
          </Box>
          <Box className={classes.metricsRow}>
            <Box className={classes.metricsRowTitleContainer}>
              <Skeleton variant="text" width="50%" height={25} />
              <Skeleton variant="text" width="40%" height={20} />
            </Box>
            <Box className={classes.metricsRowValueContainer}>
              <Skeleton variant="text" width="40%" height={25} />
              <Skeleton variant="text" width="30%" height={20} />
              <Skeleton variant="text" width="50%" height={20} />
            </Box>
          </Box>
          <Box className={cx(classes.metricsRow, classes.noBorder)}>
            <Box className={classes.metricsRowTitleContainer}>
              <Skeleton variant="text" width="50%" height={25} />
              <Skeleton variant="text" width="40%" height={20} />
            </Box>
            <Box className={classes.metricsRowValueContainer}>
              <Skeleton variant="text" width="40%" height={25} />
              <Skeleton variant="text" width="30%" height={20} />
              <Skeleton variant="text" width="50%" height={20} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.metricsTableContainer}>
          <Box className={classes.metricHeader}>
            <Typography className={classes.metricsHeaderTitle}>
              Sales overview
            </Typography>
          </Box>
          <Box className={classes.metricsRow}>
            <Box className={classes.metricsRowTitleContainer}>
              <Typography className={classes.metricsRowTitle}>
                Total sales
              </Typography>
              <Typography className={classes.metricsRowSubtitle}>
                Last period's total sales
              </Typography>
            </Box>
            <Box className={classes.metricsRowValueContainer}>
              <Typography className={classes.metricsRowValue}>
                {formatCurrencyNumber(data.current.totalOrderPrice)}
              </Typography>
              <Typography
                className={cx(
                  classes.metricsRowPercentage,
                  data.previousPercentages.totalOrderPrice < 0
                    ? 'negative'
                    : 'positive'
                )}
              >
                {data.previousPercentages.totalOrderPrice.toFixed(0)}%
              </Typography>
              <Typography className={classes.metricsRowSmallValue}>
                {formatCurrencyNumber(data.previous.totalOrderPrice)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.metricsRow}>
            <Box className={classes.metricsRowTitleContainer}>
              <Typography className={classes.metricsRowTitle}>
                Delivered orders
              </Typography>
              <Typography className={classes.metricsRowSubtitle}>
                Last period's delivered orders
              </Typography>
            </Box>
            <Box className={classes.metricsRowValueContainer}>
              <Typography className={classes.metricsRowValue}>
                {data.current.numberOfOrders}
              </Typography>
              <Typography
                className={cx(
                  classes.metricsRowPercentage,
                  data.previousPercentages.numberOfOrders < 0
                    ? 'negative'
                    : 'positive'
                )}
              >
                {data.previousPercentages.numberOfOrders.toFixed(0)}%
              </Typography>
              <Typography className={classes.metricsRowSmallValue}>
                {data.previous.numberOfOrders}
              </Typography>
            </Box>
          </Box>
          <Box className={cx(classes.metricsRow, classes.noBorder)}>
            <Box className={classes.metricsRowTitleContainer}>
              <Typography className={classes.metricsRowTitle}>
                Average order value
              </Typography>
              <Typography className={classes.metricsRowSubtitle}>
                Last period's average order value
              </Typography>
            </Box>
            <Box className={classes.metricsRowValueContainer}>
              <Typography className={classes.metricsRowValue}>
                {formatCurrencyNumber(data.current.averageOrderPrice)}
              </Typography>
              <Typography
                className={cx(
                  classes.metricsRowPercentage,
                  data.previousPercentages.averageOrderPrice < 0
                    ? 'negative'
                    : 'positive'
                )}
              >
                {data.previousPercentages.averageOrderPrice.toFixed(0)}%
              </Typography>
              <Typography className={classes.metricsRowSmallValue}>
                {formatCurrencyNumber(data.previous.averageOrderPrice)}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default BusinessScreen;
