import { Button } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

interface GenericButtonProps {
  text: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  variant?: 'text' | 'contained' | 'outlined';
  buttonColor?: string;
  textColor?: string;
}

const useStyles = makeStyles()(() => {
  return {
    button: {
      // display: "block",
      // padding: '10px 20px',
      // border: 'none',
      // borderRadius: '4px',
      // backgroundColor: '#243043',
      // color: 'white',
      // fontSize: '16px',
      // cursor: 'pointer',
      // margin: '0 auto',
      // '&:hover': {
      //     backgroundColor: '#0056b3',
      // },
      // '&:disabled': {
      //     backgroundColor: '#cccccc',
      //     cursor: 'not-allowed',
      // },
      width: '175px',
      borderRadius: '20px'
    }
  };
});

const GenericButton: React.FC<GenericButtonProps> = ({
  text,
  type = 'button',
  disabled = false,
  className = '',
  onClick,
  variant = 'contained',
  buttonColor = '#FDD229',
  textColor = '#000'
}) => {
  const { classes } = useStyles();

  return (
    <Button
      type={type}
      disabled={disabled}
      variant={variant}
      className={`${classes.button} ${className}`}
      onClick={onClick}
      style={{ backgroundColor: buttonColor, color: textColor }}
    >
      {text}
    </Button>
  );
};

export default GenericButton;
