import React from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { IOrderResponseModel } from 'src/pages/cities/cities-listing';
import CloseIcon from '@mui/icons-material/Close';
import { formatCurrencyNumber, formatDate, formatDateTime } from 'src/helpers/formatting-utils';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

const useStyles = makeStyles()({
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 500,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 24px rgba(0,0,0,0.1)',
    padding: '20px',
    borderRadius: '8px',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  orderHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #D1D3D4',
    paddingBottom: '10px',
    marginBottom: '15px',
  },
  orderHeaderItem: {
    color: '#414042',
    fontSize: '16px',
    fontWeight: 500,
  },
  sectionContainer: {
    marginBottom: '15px',
  },
  sectionTitle: {
    color: '#414042',
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  sectionItem: {
    color: '#414042',
    fontSize: '16px',
    marginBottom: '5px',
  },
  totalRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
  },
  totalRowTitle: {
    color: '#414042',
    fontSize: '16px',
    fontWeight: 500,
  },
  totalRowValue: {
    color: '#414042',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  statusBadge: {
    padding: '6px 16px', // Smaller padding for smaller badge
    borderRadius: '50px',
    textAlign: 'center',
    color: '#FFF',
    fontSize: '15px', // Smaller font size
    fontWeight: 'bold',
    marginBottom: '17px',
    display: 'inline-block', // To keep the badge small
  },
  statusPending: {
    backgroundColor: '#FF6116',
  },
  statusReady: {
    backgroundColor: '#548062',
  },
  statusPickedUp: {
    backgroundColor: '#8237c5',
  },
  statusCompleted: {
    backgroundColor: "#3DB851",
  },
  statusCancelled: {
    backgroundColor: "#CC0700",
  },
});

interface OrderDetailsModalProps {
  open: boolean;
  onClose: () => void;
  orderData: IOrderResponseModel | null;
}

const OrderDetailsModal: React.FC<OrderDetailsModalProps> = ({ open, onClose, orderData }) => {
  const { classes } = useStyles();

  const getStatusBadgeClass = (status: string) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return classes.statusPending;
      case 'ready':
        return classes.statusReady;
      case 'pickedup':
        return classes.statusPickedUp;
      case 'completed':
        return classes.statusCompleted;
      case 'cancelled':
        return classes.statusCancelled;
      default:
        return '';
    }
  };

  const formatPhoneNumber = (number: string) => {
    return isValidPhoneNumber(number) ? parsePhoneNumber(number).format('INTERNATIONAL') : number;
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modalStyle}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <Box
          className={`${classes.statusBadge} ${orderData && getStatusBadgeClass(orderData.status)}`}
        >
          {orderData?.status}
        </Box>

        <Box className={classes.orderHeaderContainer}>
          <Typography className={classes.orderHeaderItem}>ID: AB12345</Typography>
          <Typography className={classes.orderHeaderItem}>
            {formatDateTime(orderData?.createdOn)}
          </Typography>
        </Box>

        <Box className={classes.sectionContainer}>
          <Typography className={classes.sectionTitle}>Customer Information</Typography>
          <Typography className={classes.sectionItem}>
            {orderData?.contact.name} - {formatPhoneNumber(orderData?.contact.mobileNumber || '')}
          </Typography>
          <Typography className={classes.sectionItem}>{orderData?.address}</Typography>
        </Box>

        {orderData?.driver && (
          <Box className={classes.sectionContainer}>
            <Typography className={classes.sectionTitle}>Delivery Driver</Typography>
            <Typography className={classes.sectionItem}>
              {orderData.driver.name} - {formatPhoneNumber(orderData.driver.mobileNumber || '')}
            </Typography>
          </Box>
        )}

        <Box className={classes.sectionContainer}>
          <Typography className={classes.sectionTitle}>Total</Typography>
          <Box className={classes.totalRow}>
            <Typography className={classes.totalRowTitle}>Order Amount</Typography>
            <Typography className={classes.totalRowValue}>
              {formatCurrencyNumber(orderData?.totalCharge || 0)}
            </Typography>
          </Box>
          <Box className={classes.totalRow}>
            <Typography className={classes.totalRowTitle}>Delivery Charge</Typography>
            <Typography className={classes.totalRowValue}>
              {formatCurrencyNumber(orderData?.deliveryCharge || 0)}
            </Typography>
          </Box>
          <Box className={classes.totalRow}>
            <Typography className={classes.totalRowValue}>Total Bill</Typography>
            <Typography className={classes.totalRowValue}>
              {formatCurrencyNumber((orderData?.deliveryCharge || 0) + (orderData?.totalCharge || 0))}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderDetailsModal;
