import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {IUserStore} from './types';
import {
  clearUserToken,
  getUserToken,
  setUserToken,
} from '../../helpers/user-token';

const initialState: IUserStore = {
  token: '',
  user: {
    active: false,
    name: '',
    email: '',
    description: '',
    mobileNumber: '',
    defaultOrderTime: 0,
  },
  active: false,
  role: '',
  isAuthenticated: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    LoginUser: (state, action: PayloadAction<IUserStore>) => {
      setUserToken(action.payload.token);
      state = action.payload;
      state.isAuthenticated = true;
      return state;
    },
    LoadUserSuccess: (state, action: PayloadAction<IUserStore>) => {
      state = action.payload;
      state.isAuthenticated = true;
      return state;
    },
    VerifyUserAccount: (state) => {
      // state.user.status = UserStatus.Active;
      return state;
    },
    LogoutUser: (state) => {
        clearUserToken();
      state = {...initialState};
      return initialState;
    },
    UpdateDefaultOrderTime: (state, action: PayloadAction<number>) => {
      state.user.defaultOrderTime = action.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  LoginUser,
  LoadUserSuccess,
  VerifyUserAccount,
  LogoutUser,
  UpdateDefaultOrderTime,
} = userSlice.actions;

export default userSlice.reducer;
