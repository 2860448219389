import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { LogoutUser } from 'src/redux/user/userReducer';
import { UserRole } from 'src/models/roles';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { SettingsTwoTone } from '@mui/icons-material';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: #333;
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: #333;
`
);

function HeaderUserbox() {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state) => state.user);

  const user = {
    name: currentUser?.user?.name || '',
    avatar:
      'https://meckengeneral.blob.core.windows.net/cdn/icons/user-icon.svg',
    jobtitle: 'Restaurant'
  };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src={user.avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1, color: '#333' }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem to="/account-settings" component={NavLink}>
            <SettingsTwoTone fontSize="small" sx={{ color: '#333' }} />
            <ListItemText 
              primary="Account settings" 
              sx={{ ml: 1, color: '#333' }} 
            />
          </ListItem>
          <ListItem to="/resto-info" component={NavLink}>
            <SettingsTwoTone fontSize="small" sx={{ color: '#333' }} />
            <ListItemText 
              primary="Order Settings" 
              sx={{ ml: 1, color: '#333' }} 
            />
          </ListItem>
        </List>
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              dispatch(LogoutUser());
            }}
            sx={{ color: '#333' }}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1, color: '#333' }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
