import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from '@mui/material';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { makeStyles } from 'tss-react/mui';
import { isEmpty } from 'src/helpers/validation-utils';
import { cloneObjectBasic } from 'src/helpers/utils';
import GenericInput from 'src/components/input-components/GenericTextInput';
import GenericSelectInput from 'src/components/input-components/GenericSelectInput';
import GenericButton from 'src/components/GenericButton';

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  actions: {
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #D1D3D4',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  dialogTitle: {
    backgroundColor: '#FDD229',
    fontWeight: 'bold',
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  cancelAction: {
    border: '1px solid #000',
    width: '100px',
    '&:hover': {
      border: '1px solid #000'
    }
  },
  submitAction: {
    width: '150px'
  }
}));

interface ICityDetails {
  code: string;
  name: string;
  active: boolean;
  neighborhoods: {
    code: string;
    name: string;
    active: boolean;
  }[];
}

interface ILovDataResponse {
  success: boolean;
  data: ICityDetails[];
}

interface IPageStateForm {
  values: {
    address: string;
    city: string;
    neighberhood: string;
  };
  touched: Record<string, boolean>;
  errors: Record<string, string>;
}

const getInitialPageState = (): IPageStateForm => ({
  values: {
    address: '',
    city: '',
    neighberhood: ''
  },
  errors: {},
  touched: {}
});

interface IAddAddressModelProps {
  onClose: () => void;
  contactId: number;
  onSuccess: () => void;
}

const AddAddressModel: React.FC<IAddAddressModelProps> = ({
  onClose,
  onSuccess,
  contactId
}) => {
  const { classes } = useStyles();
  const [pageState, setPageState] = useState<IPageStateForm>(
    getInitialPageState()
  );
  const [citiesData, setCitiesData] = useState<ICityDetails[]>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const initialize = async () => {
      const response = await DataService.get('api/Lov/all', {});
      if (response.ok) {
        const result: ILovDataResponse = await response.json();
        setCitiesData(result.data);
      } else {
        onClose();
        toast.error('An Error Occurred');
      }
    };
    initialize();
  }, [onClose]);

  const citiesList = useMemo(
    () =>
      citiesData
        ? Object.fromEntries(citiesData.map((c) => [c.code, c.name]))
        : {},
    [citiesData]
  );

  const nbList = useMemo(() => {
    const currentCity = citiesData?.find(
      (c) => c.code === pageState.values.city
    );
    return currentCity
      ? Object.fromEntries(
          currentCity.neighborhoods.map((c) => [c.code, c.name])
        )
      : {};
  }, [citiesData, pageState.values.city]);

  const onFieldUpdate = (
    name: keyof IPageStateForm['values'],
    value: string,
    touched = false
  ) => {
    const newPageState = cloneObjectBasic(pageState);
    newPageState.values[name] = value;
    newPageState.errors[name] = validateField(name, value);
    if (touched) {
      newPageState.touched[name] = true;
    }
    if (name === 'city') {
      newPageState.values.neighberhood = '';
      newPageState.errors.neighberhood = '';
      newPageState.touched.neighberhood = false;
    }
    setPageState(newPageState);
  };

  const validateField = (name: string, value: string) => {
    if (isEmpty(value)) {
      return 'Required';
    }
    return '';
  };

  const onBlur = (name: keyof IPageStateForm['values']) => {
    const newPageState = cloneObjectBasic(pageState);
    newPageState.touched[name] = true;
    newPageState.errors[name] = validateField(name, pageState.values[name]);
    setPageState(newPageState);
  };

  const validateForm = () => {
    const newPageState = cloneObjectBasic(pageState);
    newPageState.touched = {
      address: true,
      city: true,
      neighberhood: true
    };
    newPageState.errors = {
      address: validateField('address', pageState.values.address),
      city: validateField('city', pageState.values.city),
      neighberhood: validateField('neighberhood', pageState.values.neighberhood)
    };
    setPageState(newPageState);
    return Object.values(newPageState.errors).every((a) => isEmpty(a));
  };

  const submit = async () => {
    if (!submitting) {
      const isValid = validateForm();
      if (isValid) {
        setSubmitting(true);
        const data = {
          description: pageState.values.address,
          neighborhoodCode: pageState.values.neighberhood,
          cityCode: pageState.values.city,
          contactId
        };
        const response = await DataService.post(
          'api/contact/create-address',
          data
        );
        if (response.ok) {
          onSuccess();
          onClose();
        } else {
          toast.error('An Error Occurred');
        }
        setSubmitting(false);
      } else {
        toast.error('Incomplete Form');
      }
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.dialogTitle}>Add New Address</DialogTitle>
      <DialogContent dividers className={classes.container}>
        {citiesData ? (
          <>
            <GenericInput
              type="text"
              name="address"
              title="Customer Full Address"
              value={pageState.values.address}
              onChange={(v) => onFieldUpdate('address', v)}
              error={pageState.errors.address}
              onBlur={onBlur}
              disabled={submitting}
            />
            <GenericSelectInput
              type="text"
              title="City"
              name="city"
              value={pageState.values.city}
              onChange={(v) => onFieldUpdate('city', v)}
              error={pageState.errors.city}
              items={citiesList}
              disabled={submitting}
              watermark="Select City"
            />
            <GenericSelectInput
              type="text"
              title="Neighborhood"
              name="neighberhood"
              value={pageState.values.neighberhood}
              onChange={(v) => onFieldUpdate('neighberhood', v)}
              error={pageState.errors.neighberhood}
              items={nbList}
              disabled={submitting}
              watermark="Select Neighborhood"
            />
          </>
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <GenericButton
          onClick={onClose}
          disabled={submitting}
          text={'Cancel'}
          className={classes.cancelAction}
          buttonColor={'#FFF'}
        />
        <GenericButton
          onClick={submit}
          disabled={submitting}
          text={submitting ? 'Saving...' : 'Save'}
          className={classes.submitAction}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddAddressModel;
