import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { DataService } from "./services/data-service";
import { IUserStore } from "./redux/user/types";
import { useDispatch } from "react-redux";
import { LoginUser } from "./redux/user/userReducer";
import { Protected } from "./Protected";
import { clearUserToken, getUserToken } from "./helpers/user-token";
import Loader from "./components/Loader";
import BaseLayout from "./layouts/BaseLayout";
import LoginPage from "./pages/auth-pages/Login";

interface IUserDetailsResponse {
    success: boolean;
    message: string;
    data: {
      active: boolean;
      name: string;
      email: string;
      description: string;
      mobileNumber: string;
      defaultOrderTime: number;
    };
  }

export const Portal: React.FC = () => {

    const [loaded, setLoaded] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        DataService.setBaseUrl('https://beez-app.azurewebsites.net/');
        setTimeout(async () => {
          const userToken = await getUserToken();
          DataService.get('api/Restaurant/details', {})
            .then(async (res) => {
              if (res.status === 200) {
                const result: IUserDetailsResponse = await res.json();
                let isActive = result.data.active;
                if (isActive) {
                  let newStore: IUserStore = {
                    token: userToken || '',
                    active: isActive,
                    role: 'Driver',
                    isAuthenticated: true,
                    user: result.data,
                  };
                  dispatch(LoginUser(newStore));
                } else {
                   clearUserToken();
                }
                setLoaded(true);
              } else {
                setLoaded(true);
              }
            })
            .catch(() => {
                setLoaded(true);
            });
        }, 500);
      }, []);

    return loaded ? <Routes>
        <Route path="login" element={<BaseLayout children={<LoginPage />} />} />
        <Route path="*" element={<Protected />} />
    </Routes> : <Loader size={75} />
}