import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: "100%",
            minHeight: "100vh",
            backgroundColor: "rgb(240, 242, 245)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            alignItems: "center",
            padding: " 0 0 50px",
            backgroundImage: `url(/assets/login-background.png)`,
            backgroundPosition: "bottom center",
            backgroundSize: "cover"
        },
        logo: {
            display: "block",
            width: "150px",
            height: "150px",
            margin: "0 auto 20px",
        }
    };
});

const AuthLayout: React.FC = ({ children }) => {

    const { classes } = useStyles();

    return <div className={classes.root}>
        <img className={classes.logo} src="./assets/logo.png" alt="" />
        {children}
    </div>
}

export default AuthLayout;