import { useContext } from 'react';
import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
`
);

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
  background-color: inherit;
  color: inherit;
`
);

const ContentWrapper = styled(Box)(
  ({ theme }) => `
  flex-grow: 1;
  background-color: #fff;
  color: #000;
  overflow-y: auto;
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      .MuiListItem-root {
        padding: 1px 0;
        .MuiButton-root {
          display: flex;
          color: inherit;
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.black[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.black[100], 0.06)};
            color: ${theme.colors.alpha.black[100]};
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <MenuWrapper>
      {/* <HeaderWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/overview"
                >
                  Beez Restaurant App
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </HeaderWrapper> */}
      <ContentWrapper>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Order Management
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/orders"
                  startIcon={<AccountCircleTwoToneIcon />}
                >
                  Orders
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/order-history"
                  startIcon={<AccountCircleTwoToneIcon />}
                >
                  Order history
                </Button>
              </ListItem>

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/business"
                  startIcon={<AccountCircleTwoToneIcon />}
                >
                  Business Summary
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </ContentWrapper>
    </MenuWrapper>
  );
}

export default SidebarMenu;
