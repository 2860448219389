import React, { useEffect, useState } from 'react';
// import MainLayout from "./layouts/main-layout/MainLayout";
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import { useAppSelector } from './redux/hooks';
import SidebarLayout from './layouts/SidebarLayout';
import CitiesListing from './pages/cities/cities-listing';
import CustomerPage from './pages/cities/CustomerPage';
import NeighborHoodListing from './pages/neighborhood/neighborhood-listing';
import UserListing from './pages/users/user-listing';
import DriverListing from './pages/drivers/driver-listing';
import ResturantListing from './pages/resturants/resturant-listing';
import RestuarantPage from './pages/resturants/resturantPage';
import DriverPage from './pages/drivers/driverPage';
import ResturantGroupListing from './pages/restaurant-groups/resturantGroupsPage';
import RestaurantsByGroup from './pages/restaurant-groups/restaurants-byGroup';
import PricingVersionsListing from './pages/pricing-versions/pricing-versions-listing';
import OrderEventsListing from './pages/order-logs/order-logs-listing';
import BlockedDriversListing from './pages/resturants/blockedDrivers';
import ContactListing from './pages/contacts/contact-list';
import ContactAddressListing from './pages/contacts/contact-address';
import BusinessPage from './pages/business/BusinessPage';
import AccountSettings from './pages/account-settings/AccountSettings';
import OrderHistoryListing from './pages/order-history/order-history-listing';
import UpdateRestaurantInfoScreen from './pages/account-settings/UpdateRestaurantInfoScreen';

export const Protected: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector((s) => s.user.isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { replace: true });
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return (
      <Routes>
        <Route path="" element={<SidebarLayout />}>
          <Route path="/orders" element={<CitiesListing />} />
          <Route path="/business" element={<BusinessPage />} />
          <Route path="/account-settings" element={<AccountSettings />} />
          <Route path="/resto-info" element={<UpdateRestaurantInfoScreen />} />
          <Route path="/order-history" element={<OrderHistoryListing />} />
          <Route path="*" element={<div />} />
        </Route>
      </Routes>
    );
  }

  return <div />;
};
