import React, { useEffect, useState } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router';
import { isEmpty, REGEX } from 'src/helpers/validation-utils';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import GenericInput from 'src/components/input-components/GenericTextInput';
import GenericButton from 'src/components/GenericButton';

const useStyles = makeStyles()((theme) => {
  return {
    mainContainer: {
      backgroundColor: '#fff',
      // flex: 1,
      padding: '20px 10px',
      width: '600px',
      borderRadius: '20px',
      border: '1px solid #D1D3D2',
    },
    container: {
      width: '100%',
      backgroundColor: '#fff',
      height: '100%',
      padding: '0 25px'
    },
    headerSubtitle: {
      fontSize: '12px',
      lineHeight: '18px',
      color: theme.colors.alpha.black[70],
      fontFamily: 'Poppins-Regular',
      textAlign: 'left'
    },
    infoContainer: {
      margin: '10px 0',
      padding: '20px',
      borderRadius: '20px',
      backgroundColor: '#fff',
      border: '1px solid #D1D3D2',
      display: 'flex',
      flexDirection: 'row'
    },
    changePasswordContainer: {
      flexDirection: 'column'
    },
    formSubmitButton: {
      width: '70%',
      margin: '0 auto'
    },
    infoText: {
      fontFamily: 'Poppins-Medium',
      fontSize: '15px',
      color: theme.colors.alpha.black[70],
      margin: '0 70px 10px 0'
    },
    infoTextBold: {
      fontFamily: 'Poppins-Regular',
      fontSize: '15px',
      color: theme.colors.alpha.black[70],
      margin: '0'
    },
  };
});

const AccountSettings = () => {
  const user = useAppSelector((state) => state.user.user);
  const { classes, cx } = useStyles();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const navigation = useNavigate();

  const [values, setValues] = useState<Record<string, string>>({
    oldPassword: '',
    newPassword: ''
  });
  const [errors, setErrors] = useState<Record<string, string>>({
    oldPassword: '',
    newPassword: ''
  });
  const [touched, setTouched] = useState<Record<string, boolean>>({
    oldPassword: false,
    newPassword: false
  });

  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  // const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => { };

  const handleChange = (key: string, value: string) => {
    setValues({ ...values, [key]: value });
    setTouched({ ...touched, [key]: true });
    setErrors({
      [key]: validate(key, value)
    });
  };

  const handleBlur = (key: string) => {
    setTouched({ ...touched, [key]: true });
  };

  const validate = (key: string, value: string): string => {
    let error = '';
    if (key === 'oldPassword') {
      if (isEmpty(value)) {
        error = 'Required';
      }
    } else if (key === 'newPassword') {
      if (isEmpty(value)) {
        error = 'Required';
      } else if (!REGEX.Password.test(value)) {
        error = 'At least 6 characters, with at least 1 upper & 1 number';
      }
    }

    return error;
  };

  const validateForm = () => {
    let tempTouched = { ...touched };
    let tempErrors = { ...errors };
    Object.entries(values).forEach(([key, value]) => {
      tempTouched[key] = true;
      tempErrors = { ...tempErrors, [key]: validate(key, value) };
    });
    setTouched(tempTouched);
    setErrors(tempErrors);
    return Object.values(tempErrors).every((error) => isEmpty(error));
  };

  const submit = async () => {

    if (!submitting) {
      setSubmitting(true);
      if (validateForm()) {
        try {
          const response = await DataService.post('api/Restaurant/update-password', {
            oldPassword: values.oldPassword.trim(),
            newPassword: values.newPassword.trim()
          });

          if (response.ok) {
            const result: { success: boolean } = await response.json();
            if (result.success) {
              toast.success('Your password has been changed successfully.');
            } else {
              toast.error('Invalid Credentials');
            }
          } else {
            toast.error('An Error Occured');
          }
        } catch (err) {
          toast.error('An Error Occured');
        }
      }
      setSubmitting(false);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <div className={classes.infoContainer}>
          <p className={classes.infoText}>
            Name: <p className={classes.infoTextBold}>{user.name}</p>
          </p>
          <p className={classes.infoText}>
            Mobile Number:{' '}
            <p className={classes.infoTextBold}>
              {parsePhoneNumber(user.mobileNumber).formatInternational()}
            </p>
          </p>
        </div>
        <div
          className={cx(classes.infoContainer, classes.changePasswordContainer)}
        >
          <GenericInput
            title={'Current password'}
            placeholder={'********'}
            type={'password'}
            value={values.oldPassword}
            onChange={(value) => {
              handleChange('oldPassword', value);
            }}
            name={'oldPassword'}
            onBlur={() => {
              handleBlur('oldPassword');
            }}
            disabled={submitting}
            error={touched.oldPassword ? errors.oldPassword : ''}
          />
          <GenericInput
            title={'New password'}
            placeholder={'********'}
            type={'password'}
            value={values.newPassword}
            onChange={(value) => {
              handleChange('newPassword', value);
            }}
            name={'newPassword'}
            onBlur={() => {
              handleBlur('newPassword');
            }}
            disabled={submitting}
            error={touched.newPassword ? errors.newPassword : ''}
          />
          <GenericButton
            text={'Update password'}
            className={classes.formSubmitButton}
            onClick={() => submit()}
            disabled={submitting}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
