import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        top: 0,
        left: 0,
        zIndex: 9999,
    },
    loaderGif: {
        width: 150, // Hardcoded size
        height: 150, // Hardcoded size
    }
});

const Loader: React.FC<{size: number}> = () => {
    const { classes } = useStyles();
    const gifUrl = "./assets/gif-loader.gif"; // Hardcoded GIF URL

    return (
        <div className={classes.loaderContainer}>
            <img src={gifUrl} alt="loading" className={classes.loaderGif} />
        </div>
    );
};

export default Loader;
