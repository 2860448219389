import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Skeleton
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InfoIcon from '@mui/icons-material/Info';
import { DataService } from 'src/services/data-service';
import { formatCurrencyNumber } from 'src/helpers/formatting-utils';
import { useAppDispatch } from 'src/redux/hooks';
import OrderDetailsModal from 'src/components/helper/OrderDetails';
import GenericDatePicker from 'src/components/input-components/GenericDatePicker';
import GenericButton from 'src/components/GenericButton';

interface ICustomOrderListing {
  id: string;
  customerInformation: string;
  orderAmount: number;
  deliveryCharge: number;
  totalBill: number;
  status: string;
}

export interface IOrderResponseModel {
  id: string;
  restaurantId: number;
  restaurant: {
    name: string;
    description: string;
    mobileNumber: string;
    email: string;
    active: boolean;
  };
  driverId: number;
  driver: {
    name: string;
    mobileNumber: string;
    role: string;
    active: boolean;
    onlineStatus: boolean;
  };
  customer: null;
  contact: {
    id: number;
    name: string;
    mobileNumber: string;
    contactAddresses: null;
  };
  deliveryCharge: number;
  totalCharge: number;
  address: string;
  status: string;
  paidToDriver: boolean;
  paidToRestaurant: boolean;
  createdOn: string;
}

const useStyles = makeStyles()({
  container: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    marginTop: '16px'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '35px'
  },
  tableHeader: {
    backgroundColor: '#D1D3D4',
    '& th': {
      color: '#000000',
      fontWeight: 'bold'
    }
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: '#FFF3CC'
    }
  },
  filterButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px'
  },
  filterButton: {
    border: '1px solid #D1D3D4',
    backgroundColor: '#FFFFFF',
    color: '#414042',
    padding: '10px 20px',
    borderRadius: '20px',
    fontWeight: 500,
    width: '20%',
    '&.active': {
      color: '#FFFFFF'
    }
  },
  filterButtonAll: {
    '&.active': {
      backgroundColor: '#333',
      color: "#fff"
    },
    '&:hover': {
      backgroundColor: 'rgb(51 51 51 / 90%)',
      color: "#fff"
    }
  },
  filterButtonCancelled: {
    '&.active': {
      backgroundColor: '#CC0700'
    }
  },
  filterButtonCompleted: {
    '&.active': {
      backgroundColor: '#3DB851'
    }
  },
  addOrderButton: {
    backgroundColor: '#E5B51C',
    color: '#FFFFFF',
    padding: '10px 20px',
    borderRadius: '8px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#d4a015'
    }
  },
  statusBadge: {
    padding: '8px 16px',
    borderRadius: '20px',
    color: '#FFF',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'inline-block',
    width: "100px"
  },
  statusPending: {
    backgroundColor: '#FF6116'
  },
  statusReady: {
    backgroundColor: '#548062'
  },
  statusPickedUp: {
    backgroundColor: '#8237c5'
  },
  statusCompleted: {
    backgroundColor: '#3DB851'
  },
  statusCancelled: {
    backgroundColor: '#CC0700'
  },
  dateFiltersContainer: {
    border: '1px solid #D1D3D2',
    width: '70%',
    margin: '20px auto',
    padding: '20px 30px',
    borderRadius: '16px'
  },
  dateInputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dateInputContainer: {
    width: '48%'
  },
  dateFilterButton: {
    margin: '0 auto',
    display: 'block'
  },
  skeletonRow: {
    height: 53,
  }
});

const OrderHistoryListing: React.FC = () => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const [data, setData] = useState<IOrderResponseModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<IOrderResponseModel | null>(
    null
  );
  const [startDate, setStartDate] = useState<Date>(getInitialStartDate());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
  };

  const fetchOrders = async () => {
    setLoading(true);
    const response = await DataService.get(
      `/api/Order/get-restaurant/me?startDate=${startDate.toLocaleDateString(
        'en-CA'
      )}&endDate=${endDate.toLocaleDateString('en-CA')}`,
      {}
    );
    if (response.ok) {
      const result = await response.json();
      setData(result.data);
    } else {
      setData([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const filteredData = data.filter((order) => {
    if (selectedFilter === 'all') return true;
    if (selectedFilter === 'cancelled' && order.status === 'Cancelled')
      return true;
    if (selectedFilter === 'completed' && order.status === 'Completed')
      return true;
    return false;
  });

  const handleViewDetails = (order: IOrderResponseModel) => {
    setModalContent(order);
    setOpenModal(true);
  };

  const getStatusText = (status: string) => {
    switch (status.toLowerCase()) {
      case 'pickedup':
        return "Picked up";
      default:
        return status || "";
    }
  };

  const getStatusBadgeClass = (status: string) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return classes.statusPending;
      case 'ready':
        return classes.statusReady;
      case 'pickedup':
        return classes.statusPickedUp;
      case 'completed':
        return classes.statusCompleted;
      case 'cancelled':
        return classes.statusCancelled;
      default:
        return '';
    }
  };

  function getInitialStartDate() {
    let date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h2" style={{ color: '#333', fontWeight: 600 }}>
          Order History
        </Typography>
      </div>

      <div className={classes.filterButtons}>
        <Button
          className={`${classes.filterButton} ${classes.filterButtonAll} ${selectedFilter === 'all' ? 'active' : ''
            }`}
          onClick={() => handleFilterChange('all')}
        >
          All
        </Button>
        <Button
          className={`${classes.filterButton} ${classes.filterButtonCancelled
            } ${selectedFilter === 'cancelled' ? 'active' : ''}`}
          onClick={() => handleFilterChange('cancelled')}
        >
          Cancelled
        </Button>
        <Button
          className={`${classes.filterButton} ${classes.filterButtonCompleted
            } ${selectedFilter === 'completed' ? 'active' : ''}`}
          onClick={() => handleFilterChange('completed')}
        >
          Completed
        </Button>
      </div>

      <div className={classes.dateFiltersContainer}>
        <div className={classes.dateInputsContainer}>
          <div className={classes.dateInputContainer}>
            <GenericDatePicker
              title={'Start date'}
              value={startDate}
              onChange={(value) => setStartDate(value)}
              name={'startDate'}
              maxDate={new Date()}
            />
          </div>
          <div className={classes.dateInputContainer}>
            <GenericDatePicker
              title={'End date'}
              value={endDate}
              onChange={(value) => setEndDate(value)}
              name={'endDate'}
              maxDate={new Date()}
            />
          </div>
        </div>
        <GenericButton
          text={'Filter'}
          className={classes.dateFilterButton}
          onClick={() => fetchOrders()}
        />
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>Customer Information</TableCell>
              <TableCell>Order Amount</TableCell>
              <TableCell>Delivery Charge</TableCell>
              <TableCell>Total Bill</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index} className={classes.skeletonRow}>
                  <TableCell>
                    <Skeleton variant="text" width="60%" />
                    <Skeleton variant="text" width="40%" />
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell><Skeleton variant="text" width="60%" /></TableCell>
                  <TableCell><Skeleton variant="text" width="60%" /></TableCell>
                  <TableCell><Skeleton variant="text" width="60%" /></TableCell>
                  <TableCell><Skeleton variant="rectangular" width="60%" height={25} /></TableCell>
                  <TableCell>
                    <Skeleton variant="circular" width={40} height={40} />
                  </TableCell>
                </TableRow>
              ))
              : filteredData.map((order) => (
                <TableRow
                  key={order.id}
                  className={classes.tableRowHover}
                  data-status={order.status.toLowerCase()}
                >
                  <TableCell>
                    {order.contact.name}
                    <br />
                    {order.contact.mobileNumber}
                    <br />
                    {order.address}
                  </TableCell>
                  <TableCell>{formatCurrencyNumber(order.totalCharge)}</TableCell>
                  <TableCell>{formatCurrencyNumber(order.deliveryCharge)}</TableCell>
                  <TableCell>
                    {formatCurrencyNumber(
                      order.totalCharge + order.deliveryCharge
                    )}
                  </TableCell>
                  <TableCell>
                    <span
                      className={`${classes.statusBadge} ${getStatusBadgeClass(
                        order.status
                      )}`}
                    >
                      {getStatusText(order.status)}
                    </span>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleViewDetails(order)}>
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <OrderDetailsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        orderData={modalContent}
      />
    </div>
  );
};

export default OrderHistoryListing;
