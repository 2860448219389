import React, { useEffect, useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { formatCurrencyNumber } from 'src/helpers/formatting-utils';
import NewOrderScreen from '../forms/NewOrderModal';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { useAppDispatch } from 'src/redux/hooks';
import OrderDetailsModal from 'src/components/helper/OrderDetails';

interface ICustomOrderListing {
  id: string;
  customerInformation: string;
  orderAmount: number;
  deliveryCharge: number;
  totalBill: number;
  status: string;
}

export interface IOrderResponseModel {
  id: string;
  restaurantId: number;
  restaurant: {
    name: string;
    description: string;
    mobileNumber: string;
    email: string;
    active: boolean;
  };
  driverId: number;
  driver: {
    name: string;
    mobileNumber: string;
    role: string;
    active: boolean;
    onlineStatus: boolean;
  };
  customer: null;
  contact: {
    id: number;
    name: string;
    mobileNumber: string;
    contactAddresses: null;
  };
  deliveryCharge: number;
  totalCharge: number;
  address: string;
  status: string;
  paidToDriver: boolean;
  paidToRestaurant: boolean;
  createdOn: string;
}

const useStyles = makeStyles()({
  container: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    marginTop: '16px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '35px',
  },
  tableHeader: {
    backgroundColor: '#D1D3D4',
    '& th': {
      color: '#000000',
      fontWeight: 'bold',
    },
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: '#FFF3CC',
    },
  },
  filterButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
  },
  filterButton: {
    border: '1px solid #D1D3D4',
    backgroundColor: '#FFFFFF',
    color: '#414042',
    padding: '10px 20px',
    borderRadius: '20px',
    fontWeight: 500,
    width: '20%',
    '&.active': {
      color: '#FFFFFF',
    },
  },
  filterButtonAll: {
    '&.active': {
      backgroundColor: '#333',
      color: "#fff"
    },
    '&:hover': {
      backgroundColor: 'rgb(51 51 51 / 90%)',
      color: "#fff"
    }
  },
  filterButtonPending: {
    '&.active': {
      backgroundColor: '#FF6116',
    },
  },
  filterButtonReady: {
    '&.active': {
      backgroundColor: '#548062',
    },
  },
  filterButtonPickedUp: {
    '&.active': {
      backgroundColor: '#8237c5',
    },
  },
  addOrderButton: {
    backgroundColor: '#E5B51C',
    padding: '10px 20px',
    borderRadius: '8px',
    color: "#333",
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#d4a015',
    },
  },
  statusBadge: {
    padding: '8px 16px',
    borderRadius: '20px',
    color: '#FFF',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'inline-block',
    width: "100px"
  },
  statusPending: {
    backgroundColor: '#FF6116',
  },
  statusReady: {
    backgroundColor: '#548062',
  },
  statusPickedUp: {
    backgroundColor: '#8237c5',
  },
  statusCompleted: {
    backgroundColor: "#3DB851",
  },
  statusCancelled: {
    backgroundColor: "#CC0700",
  },
  skeletonRow: {
    height: 53,
  },
});

const OrdersListing: React.FC = () => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const [data, setData] = useState<IOrderResponseModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<IOrderResponseModel | null>(null);
  const [newOrderModalOpen, setNewOrderModalOpen] = useState<boolean>(false);

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
  };

  const fetchOrders = async () => {
    setLoading(true);
    const response = await DataService.get('/api/Restaurant/get-active-orders', {});
    if (response.ok) {
      const result = await response.json();
      setData(result.data);
    } else {
      setData([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const filteredData = data.filter((order) => {
    if (selectedFilter === 'all') return true;
    if (selectedFilter === 'preparing' && order.status === 'Pending') return true;
    if (selectedFilter === 'ready' && order.status === 'Ready') return true;
    if (selectedFilter === 'pickedup' && order.status === 'Pickedup') return true;
    return false;
  });

  const handleReady = async (id: string) => {
    const response = await DataService.post(`api/Order/ready/${id}`, {});
    if (response.ok) {
      toast.success('Order marked as ready!');
      fetchOrders();
    } else {
      toast.error('An error occurred');
    }
  };

  const handleCancel = async (id: string) => {
    const response = await DataService.post(`api/Order/cancel/${id}`, {});
    if (response.ok) {
      toast.success('Order cancelled!');
      fetchOrders();
    } else {
      toast.error('An error occurred');
    }
  };

  const cancelOrder = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Cancel Order',
      message: 'Are you sure you want to cancel this order?',
      onSubmit: () => handleCancel(id)
    };
    dispatch(OpenConfirmation(confimation));
  };

  const handleViewDetails = (order: IOrderResponseModel) => {
    setModalContent(order);
    setOpenModal(true);
  };

  const handleAddOrder = () => {
    setNewOrderModalOpen(true);
  };

  const handleNewOrderSuccess = () => {
    setNewOrderModalOpen(false);
    fetchOrders();
  };

  const getStatusBadgeClass = (status: string) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return classes.statusPending;
      case 'ready':
        return classes.statusReady;
      case 'pickedup':
        return classes.statusPickedUp;
      case 'completed':
        return classes.statusCompleted;
      case 'cancelled':
        return classes.statusCancelled;
      default:
        return '';
    }
  };

  const getStatusText = (status: string) => {
    switch (status.toLowerCase()) {
      case 'pickedup':
        return "Picked up";
      default:
        return status || "";
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h2" style={{ color: '#E5B51C', fontWeight: 600 }}>
          Orders
        </Typography>
        <Button className={classes.addOrderButton} onClick={handleAddOrder}>
          + Add Order
        </Button>
      </div>

      <div className={classes.filterButtons}>
        <Button className={`${classes.filterButton} ${classes.filterButtonAll} ${selectedFilter === 'all' ? 'active' : ''}`} onClick={() => handleFilterChange('all')}>All</Button>
        <Button className={`${classes.filterButton} ${classes.filterButtonPending} ${selectedFilter === 'preparing' ? 'active' : ''}`} onClick={() => handleFilterChange('preparing')}>Preparing</Button>
        <Button className={`${classes.filterButton} ${classes.filterButtonReady} ${selectedFilter === 'ready' ? 'active' : ''}`} onClick={() => handleFilterChange('ready')}>Ready</Button>
        <Button className={`${classes.filterButton} ${classes.filterButtonPickedUp} ${selectedFilter === 'pickedup' ? 'active' : ''}`} onClick={() => handleFilterChange('pickedup')}>Picked up</Button>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>Customer Information</TableCell>
              <TableCell>Order Amount</TableCell>
              <TableCell>Delivery Charge</TableCell>
              <TableCell>Total Bill</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <TableRow key={index} className={classes.skeletonRow}>
                    <TableCell>
                      <Skeleton variant="text" width="60%" />
                      <Skeleton variant="text" width="40%" />
                      <Skeleton variant="text" width="80%" />
                    </TableCell>
                    <TableCell><Skeleton variant="text" width="60%" /></TableCell>
                    <TableCell><Skeleton variant="text" width="60%" /></TableCell>
                    <TableCell><Skeleton variant="text" width="60%" /></TableCell>
                    <TableCell><Skeleton variant="rectangular" width="60%" height={25} /></TableCell>
                    <TableCell>
                      <Skeleton variant="circular" width={40} height={40} />
                    </TableCell>
                  </TableRow>
                ))
              : filteredData.map((order) => (
                  <TableRow key={order.id} className={classes.tableRowHover} data-status={order.status.toLowerCase()}>
                    <TableCell>
                      {order.contact.name}<br />
                      {order.contact.mobileNumber}<br />
                      {order.address}
                    </TableCell>
                    <TableCell>{formatCurrencyNumber(order.totalCharge)}</TableCell>
                    <TableCell>{formatCurrencyNumber(order.deliveryCharge)}</TableCell>
                    <TableCell>{formatCurrencyNumber(order.totalCharge + order.deliveryCharge)}</TableCell>
                    <TableCell>
                      <span className={`${classes.statusBadge} ${getStatusBadgeClass(order.status)}`}>{getStatusText(order.status)}</span>
                    </TableCell>
                    <TableCell>
                    {order.status.toLowerCase() === 'pending' && (
                        <IconButton onClick={() => handleReady(order.id)}>
                          <CheckIcon />
                        </IconButton>
                      )}
                      {order.status.toLowerCase() !== 'pickedup' && (
                        <IconButton onClick={() => cancelOrder(order.id)}>
                          <CancelIcon />
                        </IconButton>
                      )}

                      {/* <IconButton onClick={() => handleViewDetails(order)}>
                        <InfoIcon />
                      </IconButton> */}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>

      <OrderDetailsModal 
        open={openModal}
        onClose={() => setOpenModal(false)}
        orderData={modalContent}
      />

      {newOrderModalOpen && (
        <NewOrderScreen
          open={newOrderModalOpen}
          onClose={() => setNewOrderModalOpen(false)}
          onSuccess={handleNewOrderSuccess}
        />
      )}
    </div>
  );
};

export default OrdersListing;
