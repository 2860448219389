import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Divider
} from '@mui/material';
import { toast } from 'react-toastify';
import { isEmpty, isValidNumber } from 'src/helpers/validation-utils';
import { useAppSelector } from 'src/redux/hooks';
import { formatCurrencyNumber } from 'src/helpers/formatting-utils';
import { DataService } from 'src/services/data-service';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { makeStyles } from 'tss-react/mui';
import CreateCustomerModal from './modal/CreateCustomerModal';
import AddAddressModal from './modal/AddAddressModal';
import GenericNumberInput from 'src/components/input-components/GenericNumberInput';
import GenericPhoneInput from 'src/components/input-components/GenericPhoneInput';
import GenericButton from 'src/components/GenericButton';

const useStyles = makeStyles()((theme) => ({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  header: {
    backgroundColor: '#FDD229',
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  headerTitle: {
    color: '#000',
    fontSize: 20,
    lineHeight: '30px',
    fontFamily: 'Poppins-Regular',
  },
  body: {
    paddingHorizontal: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(5)
  },
  section: {
    marginBottom: theme.spacing(3)
  },
  radioGroup: {
    paddingVertical: theme.spacing(2)
  },
  actions: {
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #D1D3D4',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  divider: {
    margin: `10px 0`
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  newAddressButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 'unset'
  },
  dialogTitle: {
    backgroundColor: '#FDD229',
    fontWeight: 'bold',
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  searchSection: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  searchButton: {
    // marginLeft: theme.spacing(1),
    // borderRadius: '19px',
    // backgroundColor: '#FDD229',
    // '&:hover': {
    //   backgroundColor: '#FDD229'
    // }
    width: '150px',
    marginLeft: theme.spacing(4),
    marginTop: '6px'
  },
  closeButton: {
    border: '1px solid #000',
    width: '175px',
    '&:hover': {
      border: '1px solid #000'
    }
  }
}));

const getInitialData = (orderTime: number) => ({
  contactId: '',
  address: '',
  deliveryCharge: 0,
  totalCharge: '',
  contactAddressId: '',
  orderTime
});

interface IContactSearchResponse {
  success: boolean;
  message: null;
  data: {
    id: number;
    name: string;
    mobileNumber: string;
    contactAddresses: [
      {
        id: number;
        cityName: string;
        neighborhoodName: string;
        addressText: string;
        pricingCharge: number;
      }
    ];
  };
}

interface ISelectedContact {
  id: number;
  name: string;
  mobileNumber: string;
  addresses: IContactAddress[];
}

interface IContactAddress {
  id: number;
  city: string;
  neighberhood: string;
  addressText: string;
  pricingCharge: number;
}

interface IDataToSubmit {
  contactId: string;
  deliveryCharge: number;
  totalCharge: string;
  contactAddressId: string;
  orderTime: number;
}

const NewOrderScreen: React.FC<{
  open: boolean;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ open, onClose, onSuccess }) => {
  const { classes } = useStyles();
  const user = useAppSelector((state) => state.user);
  const [searchText, setSearchText] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<ISelectedContact>();
  const [addressRadioGroup, setAddressRadioGroup] = useState<
    Record<string, string>
  >({});
  const [data, setData] = useState<IDataToSubmit>(
    getInitialData(user.user.defaultOrderTime)
  );
  const [addCustomerVisible, setAddCustomerVisible] = useState<boolean>(false);
  const [addAddressVisible, setAddAddressVisible] = useState<boolean>(false);

  useEffect(() => {
    if (selectedContact) {
      const result: Record<string, string> = {};
      selectedContact.addresses.forEach((c) => {
        result[c.id] = `${c.addressText} \n ${c.neighberhood}, ${c.city}`;
      });
      setAddressRadioGroup(result);

      // Auto-select the address if only one is available
      if (selectedContact.addresses.length === 1) {
        setData((prevData) => ({
          ...prevData,
          contactAddressId: selectedContact.addresses[0].id.toString(),
          deliveryCharge: selectedContact.addresses[0].pricingCharge
        }));
      }
    }
  }, [selectedContact]);

  const onSearchCustomer = async (v: string = searchText) => {
    if (!searching && v.trim() !== '') {
      let valueToSearch = v.replace(/\s/g, '');
      if (!valueToSearch.startsWith('+')) {
        valueToSearch = '+' + valueToSearch;
      }
      if (isPossiblePhoneNumber(valueToSearch)) {
        if (valueToSearch.startsWith('+961')) {
          setSearching(true);
          const response = await DataService.get(
            'api/Contact/get-mobile?number=' +
              encodeURIComponent(valueToSearch),
            {}
          );
          setSearching(false);
          if (response.ok) {
            const result: IContactSearchResponse = await response.json();
            if (result.success) {
              const newContact: ISelectedContact = {
                id: result.data.id,
                name: result.data.name,
                mobileNumber: result.data.mobileNumber,
                addresses: result.data.contactAddresses.map((c) => ({
                  id: c.id,
                  city: c.cityName,
                  neighberhood: c.neighborhoodName,
                  addressText: c.addressText,
                  pricingCharge: c.pricingCharge
                }))
              };
              setSelectedContact(newContact);
              setData({
                ...getInitialData(user.user.defaultOrderTime),
                contactId: result.data.id.toString()
              });
            } else {
              setSelectedContact(undefined);
              setData(getInitialData(user.user.defaultOrderTime));
              setAddCustomerVisible(true); // Automatically open create customer modal
            }
          } else {
            setSelectedContact(undefined);
            setData(getInitialData(user.user.defaultOrderTime));
            toast.error('An error occurred. Please try again.');
          }
        } else {
          toast.error('Insert a valid Lebanese phone number');
        }
      } else {
        toast.error('Invalid Mobile Number');
      }
    }
  };

  const onDataUpdate = (name: keyof IDataToSubmit, v: any) => {
    const newData = { ...data, [name]: v };
    if (name === 'contactAddressId') {
      const currentAddress = selectedContact?.addresses.find(
        (a) => a.id === Number(v || 0)
      );
      if (currentAddress) {
        newData['deliveryCharge'] = currentAddress.pricingCharge;
      }
    }
    setData(newData);
  };

  const validate = () => {
    if (isEmpty(data.contactAddressId)) {
      toast.error('Select an address');
      return false;
    }

    if (
      isEmpty(data.totalCharge) ||
      !isValidNumber(data.totalCharge) ||
      Number(data.totalCharge) <= 0
    ) {
      toast.error('Enter a valid order amount');
      return false;
    }

    if (Number(data.orderTime) <= 0) {
      toast.error('Order preparation time must be greater than zero');
      return false;
    }

    return true;
  };

  const initializeOrders = async () => {
    // Initialize orders here if needed
  };

  const submit = async () => {
    if (validate()) {
      if (!submitting) {
        setSubmitting(true);
        const selectedAddress = selectedContact?.addresses.find(
          (c) => c.id.toString() === data.contactAddressId.toString()
        );
        const dataToSubmit = {
          contactId: Number(selectedContact?.id),
          address: `${selectedAddress?.city}, ${selectedAddress?.neighberhood}, ${selectedAddress?.addressText} `,
          deliveryCharge: Number(data.deliveryCharge),
          totalCharge: Number(data.totalCharge),
          contactAddressId: Number(data.contactAddressId),
          orderTime: Number(data.orderTime)
        };

        const response = await DataService.post(
          'api/Order/create',
          dataToSubmit
        );
        setSubmitting(false);
        if (response.ok) {
          const result: { success: boolean } = await response.json();
          if (result.success) {
            await initializeOrders();
            setSelectedContact(undefined);
            setData(getInitialData(user.user.defaultOrderTime));
            toast.success('Order placed!');
            onSuccess();
          } else {
            toast.error('An error occurred while placing the order.');
          }
        } else {
          toast.error('An error occurred while placing the order.');
        }
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle className={classes.dialogTitle}>
          Manual Order Entry
        </DialogTitle>
        <DialogContent dividers className={classes.container}>
          <div className={classes.searchSection}>
            <GenericPhoneInput
              type="text"
              name="search"
              title="Search"
              value={searchText}
              onChange={(v) => setSearchText(v)}
              onBlur={() => onSearchCustomer()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSearchCustomer();
                }
              }}
              disabled={submitting || searching}
            />
            <GenericButton
              variant="contained"
              onClick={() => onSearchCustomer()}
              disabled={submitting || searching}
              className={classes.searchButton}
              text={'Search'}
            />
          </div>
          {selectedContact ? (
            <>
              <Typography variant="h6" className={classes.headerTitle}>
                Order for {selectedContact.name}
              </Typography>
              <Divider className={classes.divider} />
              {/* <Typography variant="h6" className={classes.headerTitle}>
                Order Details
              </Typography> */}
              <RadioGroup
                className={classes.radioGroup}
                value={data.contactAddressId || ''}
                onChange={(e) =>
                  onDataUpdate('contactAddressId', e.target.value)
                }
              >
                {Object.keys(addressRadioGroup).map((key) => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio color="secondary" />}
                    label={addressRadioGroup[key]}
                    disabled={searching || submitting}
                  />
                ))}
              </RadioGroup>
              <GenericButton
                variant="contained"
                onClick={() => setAddAddressVisible(true)}
                className={classes.newAddressButton}
                disabled={submitting || searching}
                text={'+ Add New Address'}
              />
              <GenericNumberInput
                name="totalCharge"
                title="Order Amount"
                value={data.totalCharge || ''}
                onChange={(v) => onDataUpdate('totalCharge', v)}
                disabled={submitting || searching}
              />
              <Typography className={classes.textField}>
                Delivery Charge: {formatCurrencyNumber(data.deliveryCharge)}
              </Typography>
              <Typography className={classes.textField}>
                Total:{' '}
                {isValidNumber(data.deliveryCharge) &&
                isValidNumber(data.totalCharge)
                  ? formatCurrencyNumber(
                      Number(data.deliveryCharge) + Number(data.totalCharge)
                    )
                  : '--'}
              </Typography>
              <GenericNumberInput
                name="orderTime"
                title="Set Order Preparation Time"
                value={data.orderTime?.toString() || ''}
                onChange={(v) => onDataUpdate('orderTime', v)}
                disabled={submitting || searching}
              />
            </>
          ) : (
            <Typography>
              No customer selected. Please search for a customer.
            </Typography>
          )}
          {searching && (
            <CircularProgress
              style={{ display: searching ? 'block' : 'none' }}
              color={'secondary'}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <GenericButton
            variant="outlined"
            onClick={() => {
              onClose();
            }}
            disabled={submitting || searching}
            className={classes.closeButton}
            text={'Close'}
            buttonColor={'#FFF'}
          />
          {selectedContact && (
            <GenericButton
              onClick={submit}
              disabled={submitting || searching}
              text={submitting ? 'Placing Order...' : 'Place Order'}
            />
          )}
        </DialogActions>
      </Dialog>

      {addCustomerVisible && (
        <CreateCustomerModal
          mobileNumber={searchText}
          onSuccess={(v) => {
            setSearchText(v);
            onSearchCustomer(v);
          }}
          onClose={() => setAddCustomerVisible(false)}
        />
      )}

      {!!selectedContact && addAddressVisible && (
        <AddAddressModal
          onSuccess={onSearchCustomer}
          contactId={selectedContact.id}
          onClose={() => setAddAddressVisible(false)}
        />
      )}
    </>
  );
};

export default NewOrderScreen;
