import { getUserToken } from '../helpers/user-token';

export class DataService {
  private loaded = false;
  private static baseUrl: string | null = null;

  private constructor() {
    this.loaded = true;
  }

  public static setBaseUrl(token: string): void {
    DataService.baseUrl = token;
  }

  public static async get(
    url: string,
    initHeaders?: HeadersInit,
    isAuthorized: boolean = true
  ): Promise<Response> {
    let headers = new Headers({
      ...{ 'Content-Type': 'application/json' },
      ...initHeaders
    });
    if (isAuthorized) {
      headers.append('Authorization', `Bearer ${getUserToken()}`);
    }

    const response = await fetch(this.baseUrl + url, {
      method: 'GET',
      headers: headers
    });
    return response;
  }

  public static async getWithParams(
    url: string,
    params: Record<string, string>,
    initHeaders?: HeadersInit,
    isAuthorized: boolean = true,
  ): Promise<Response> {
    let headers = new Headers({
      ...{'Content-Type': 'application/json'},
      ...initHeaders,
    });
    if (isAuthorized) {
      var userToken = (await getUserToken()) || '';
      headers.append('Authorization', `Bearer ${userToken}`);
    }

    let paramsString = new URLSearchParams(params).toString();

    let finalUrl = url;
    if (paramsString) {
      finalUrl += `?${paramsString}`;
    }

    const response = await fetch(this.baseUrl + finalUrl, {
      method: 'GET',
      headers: headers,
    });
    return response;
  }

  public static async delete(
    url: string,
    data: any,
    initHeaders?: HeadersInit,
    isAuthorized: boolean = true
  ): Promise<Response> {
    let headers = new Headers({
      ...{ 'Content-Type': 'application/json' },
      ...initHeaders
    });
    if (isAuthorized) {
      headers.append('Authorization', `Bearer ${getUserToken()}`);
    }
    const response = await fetch(this.baseUrl + url, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(data)
    });
    return response;
  }

  public static async post(
    url: string,
    data: any,
    initHeaders?: HeadersInit,
    isAuthorized: boolean = true
  ): Promise<Response> {
    let headers = new Headers({
      ...{ 'Content-Type': 'application/json' },
      ...initHeaders
    });
    if (isAuthorized) {
      headers.append('Authorization', `Bearer ${getUserToken()}`);
    }
    const response = await fetch(this.baseUrl + url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    });
    return response;
  }

  public static async put(
    url: string,
    data: any,
    initHeaders?: HeadersInit,
    isAuthorized: boolean = true
  ): Promise<Response> {
    let headers = new Headers({
      ...{ 'Content-Type': 'application/json' },
      ...initHeaders
    });
    if (isAuthorized) {
      headers.append('Authorization', `Bearer ${getUserToken()}`);
    }
    const response = await fetch(this.baseUrl + url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(data)
    });
    return response;
  }

  public static async postForm(
    url: string,
    formData: FormData,
    initHeaders?: HeadersInit,
    isAuthorized: boolean = true
  ): Promise<Response> {
    let headers = new Headers({ ...initHeaders });
    if (isAuthorized) {
      headers.append('Authorization', `Bearer ${getUserToken()}`);
    }
    const response = await fetch(this.baseUrl + url, {
      method: 'POST',
      headers: headers,
      body: formData
    });
    return response;
  }
}
